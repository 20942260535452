/* @import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto+Condensed:wght@300;400;700&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

html,
body,
#root,
.app {
  min-height: 100%;
  width: 100%;
  font-family: "Rubik", sans-serif;
  overflow-x: hidden;
}

a {
  text-decoration: none;
  color: inherit;
}

#svg {
  stroke: white;
  fill-opacity: 0;
  stroke-width: 1px;
  stroke-dasharray: 4500;
}

.letter {
  position: relative;
}
