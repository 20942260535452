.bg-gradient {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
  overflow: hidden;
  pointer-events: none;
}

.circle-01 {
  width: 50%;
  height: 50%;
  background: rgb(122, 164, 231);
  position: absolute;
  top: 0;
  right: -100px;
  border-radius: 50%;
  filter: blur(150px);
  animation: moveElement 10s linear infinite alternate;
}

.circle-02 {
  width: 50%;
  height: 250px;
  background: rgb(252, 125, 201);
  position: absolute;
  top: 50%;
  left: -100px;
  border-radius: 50%;
  filter: blur(150px);
  animation: moveElement 5s linear infinite alternate;
}

.circle-03 {
  width: 75%;
  height: 250px;
  background: hsla(16, 93%, 78%, 1);
  position: absolute;
  top: 80%;
  left: 100px;
  border-radius: 50%;
  filter: blur(150px);
  animation: moveElement 15s linear infinite alternate;
}

@keyframes rotateMesh {
  0% {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
  }
}

@keyframes moveElement {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(-100vw - 20px), calc(50vh - 100px));
  }
}
